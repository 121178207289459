<template>
  <div>
    <el-popover ref="popover" trigger="click">
      <div class="tree-box">
        <div class="tree-box-search">
          <!-- 搜索框 -->
          <el-input
            v-model="filterText"
            placeholder="输入关键字进行过滤"
            style="width: 100%"
            size="small"
          ></el-input>
        </div>
        <!-- 组织架构树 -->
        <div>
          <el-tree
            class="filter-tree"
            :data="treeData"
            :props="defaultProps"
            default-expand-all
            node-key="id"
            :filter-node-method="filterNode"
            show-checkbox
            ref="tree"
            @check="selectNodeChange"
          >
            <div slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <el-tag>111</el-tag>
            </div>
          </el-tree>
        </div>
      </div>
      <div
        class="mian-container el-select"
        style="display: block; height: 32px"
        slot="reference"
      >
        <div
          :class="{ 'el-input__inner': true, 'disabled-s': disabled }"
          style="height: 32px"
        >
          <div
            style="
              white-space: nowrap;
              overflow: hidden;
              height: 32px;
              line-height: 32px;
            "
          >
            {{ currentNode.title }}
          </div>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
//TODO 还没写完的组件
import { getRelateTreeData } from "@/api/SystemData";
import emitter from "element-ui/src/mixins/emitter";
export default {
  name: "SdEmployeeSelection",
  model: {
    prop: "modeValue",
    event: "update:modelValue",
  },
  mixins: [emitter],
  inject: {
    elForm: {
      default: "",
    },
    elFormItem: {
      default: "",
    },
  },
  props: {
    modeValue: {
      type: [String, Number, Array, Object, Boolean],
      default: () => {
        return "";
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "请选择员工",
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.modeValue;
      },
      set(val) {
        this.$refs.tree.setCheckedKeys([val]);
      },
    },
  },
  data() {
    return {
      filterText: "",
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      currentNode: {},
    };
  },
  created() {
    //this.init();
  },
  mounted() {
    this.init();
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
    init() {
      getRelateTreeData({ tenantId: this.$store.state.tenantId }).then(
        (res) => {
          this.treeData = res.data.deptVOS;
          this.setNode();
        }
      );
    },
    selectNodeChange(e) {
      if (e.type && e.type == 2) {
        this.currentNode = e;
        this.$refs.tree.setCheckedKeys([e.id]);
        this.$emit("update:modelValue", e.id);
        this.$emit("changeChecked", e);
        this.dispatch("ElFormItem", "el.form.change", e.id);
        this.$refs.popover.doClose();
      } else {
        this.$message.error("请选择员工！");
        this.$refs.tree.setCheckedKeys([]);
      }
    },
    getCheckedNodes() {
      //this.currentNode = this.$refs.tree.getCheckedKeys();
      console.log(this.$refs.tree.getCheckedNodes());
    },
    setNode() {
      this.$refs.tree.setCheckedKeys([this.modeValue]);
      this.$forceUpdate();
      this.getCheckedNodes();
    },
  },
};
</script>

<style lang="scss" scoped>
.tree-box {
  max-height: 400px;
  max-width: 400px;
  overflow: auto;
  position: relative;
  &-search {
    position: sticky;
    top: 0;
  }
}
.disabled-s {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}
.placeholder-style {
  color: #ccc;
}
</style>
