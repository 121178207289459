<template>
  <div class="el-collapse-box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      inline
      ref="ruleForm"
      label-position="top"
      label-width="100px"
      class="el-collapse-box"
    >
      <AssociationScroll>
        <AssociationScrollView title="基本信息" name="0">
          <div class="container container-box">
            <el-form-item prop="insuranceTypeKey">
              <TextInput
                :isText="!isEdit"
                @returnVal="getKeyToValue"
                v-model="ruleForm.insuranceTypeKey"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                :config="{
                  type: 'select',
                  option: dictList.AZXInsuranceType,
                  modelTextKey: 'insuranceTypeName',
                  required: true,
                  label: '保险类型:',
                }"
              >
              </TextInput>
            </el-form-item>
            <el-form-item prop="insuranceDate" ref="insuranceDate">
              <TextInput
                :isText="!isEdit"
                v-model="ruleForm.insuranceDate"
                :form="ruleForm"
                @returnVal="getKeyToValue"
                :config="{
                  type: 'daterange',
                  daterangeKeys: ['insuranceStartDate', 'insuranceEndDate'],
                  daterangeFormats: ['YYYY-MM-DD', 'YYYY-MM-DD'],
                  modelTextKey: 'insuranceDate',
                  required: true,
                  label: '保险期限:',
                }"
              >
              </TextInput>
            </el-form-item>
            <el-form-item prop="periodStartEndTime">
              <TextInput
                :isText="!isEdit"
                @returnVal="getKeyToValue"
                v-model="ruleForm.periodStartEndTime"
                :config="{
                  required: true,
                  label: '起止时间：',
                  type: 'radio',
                  option: [
                    {
                      label: '12时-12时',
                      value: 1,
                    },
                    {
                      label: '0时-24时',
                      value: 2,
                    },
                  ],
                  daterangeFormats: ['YYYY-MM-DD', 'YYYY-MM-DD'],
                  modelTextKey: 'periodStartEndTimeName',
                }"
              >
              </TextInput>
            </el-form-item>
            <el-form-item prop="salesmanId">
              <TextInput
                :isText="!isEdit"
                :config="{
                  type: 'slot',
                  label: '业务员：',
                  required: true,
                }"
                v-model="ruleForm.salesmanName"
              >
                <EmployeeSelect
                  v-model="ruleForm.salesmanId"
                  placeholder="请选择"
                  @changeChecked="carryOutData($event, 'salesmanId')"
                />
              </TextInput>
            </el-form-item>
            <!-- <el-form-item prop="salesmanId">
              <TextInput
                :isText="!isEdit"
                :config="{
                  type: 'slot',
                  label: '业务员222：',
                  required: true,
                }"
                v-model="ruleForm.salesmanName"
              >
                <SdEmployeeSelection
                  v-model="ruleForm.salesmanId"
                  placeholder="请选择"
                  @changeChecked="carryOutData($event, 'salesmanId')"
                />
              </TextInput>
            </el-form-item> -->
            <el-form-item prop="inputPersonName" ref="inputPersonName">
              <TextInput
                :isText="!isEdit"
                v-model="ruleForm.inputPersonName"
                :config="{
                  disabled: true,
                  type: 'input',
                  label: '录单员：',
                }"
              >
              </TextInput>
            </el-form-item>

            <el-form-item prop="projectId" ref="projectId">
              <div class="el-input el-input--small el-input--suffix">
                <TextInput
                  @click.native="isEdit ? (bindProjectOpen = true) : ''"
                  :isText="!isEdit"
                  v-model="ruleForm.projectName"
                  :config="{
                    type: 'input',
                    required: true,
                    label: '项目名称：',
                  }"
                >
                </TextInput>
              </div>
            </el-form-item>
            <el-form-item prop="projectRemarks">
              <TextInput
                :isText="!isEdit"
                v-model="ruleForm.projectRemarks"
                :config="{
                  type: 'input',
                  label: '项目备注：',
                }"
              >
              </TextInput>
            </el-form-item>
            <el-form-item prop="customerId" ref="customerId">
              <TextInput
                :isText="!isEdit"
                @returnVal="getKeyToValue"
                v-model="ruleForm.customerId"
                :optionConfig="{
                  value: 'userId',
                  label: 'dictValue',
                }"
                :config="{
                  type: 'select',
                  option: dictList.customerList,
                  modelTextKey: 'customerName',
                  label: '绑定客户：',
                }"
              >
              </TextInput>
            </el-form-item>
            <el-form-item prop="policyNumber">
              <TextInput
                :isText="!isEdit"
                v-model="ruleForm.policyNumber"
                :config="{
                  type: 'input',
                  label: '保单号：',
                  required: true,
                }"
              >
              </TextInput>
            </el-form-item>
            <el-form-item
              prop="salesmanDepartmentName"
              ref="salesmanDepartmentName"
            >
              <TextInput
                :isText="!isEdit"
                v-model="ruleForm.salesmanDepartmentName"
                :config="{
                  disabled: true,
                  type: 'input',
                  required: true,
                  label: '业务员所属部门：',
                }"
              >
              </TextInput>
            </el-form-item>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="承保公司" name="1">
          <template v-if="isEdit" slot="head-right">
            <el-button
              type="success"
              icon="el-icon-circle-plus-outline"
              size="small"
              @click="
                dataItemAdd({
                  tp: 'insInsurerTemplate',
                  lk: 'ruleForm.insInsurerList',
                })
              "
              >添加</el-button
            >
          </template>
          <div class="container">
            <div
              v-for="(item, index) in ruleForm.insInsurerList"
              :key="index"
              :ref="'acceptCompanyList' + index"
              class="container-box"
              style="position: relative"
            >
              <el-form-item
                :prop="'insInsurerList.' + index + '.companyKey'"
                :rules="{
                  required: true,
                  message: '请选择保险公司',
                  trigger: 'change',
                }"
              >
                <div style="display: flex; align-items: center">
                  <TextInput
                    :isText="!isEdit"
                    @returnVal="getKeyToValue($event, item)"
                    v-model="item.companyKey"
                    :optionConfig="{
                      value: 'dictKey',
                      label: 'dictValue',
                    }"
                    :config="{
                      type: 'select',
                      option: dictList.insuranceCompany,
                      modelTextKey: 'companyName',
                      label: '保险公司：',
                      required: true,
                    }"
                  >
                  </TextInput>
                  <div v-if="!isEdit && item.isPrimary == 1" class="is-primary">
                    主承保公司
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                :prop="'insInsurerList.' + index + '.branchKey'"
                :rules="{
                  required: true,
                  message: '请选择分支机构',
                  trigger: 'change',
                }"
              >
                <TextInput
                  :isText="!isEdit"
                  @returnVal="getKeyToValue($event, item)"
                  v-model="item.branchKey"
                  :optionConfig="{
                    value: 'dictKey',
                    label: 'dictValue',
                  }"
                  :config="{
                    type: 'select',
                    option: item.branchList,
                    modelTextKey: 'branchName',
                    label: '分支机构：',
                    required: true,
                  }"
                >
                </TextInput>
              </el-form-item>
              <el-form-item
                :prop="'insInsurerList.' + index + '.coverageRatio'"
              >
                <div style="display: flex">
                  <TextInput
                    :isText="!isEdit"
                    v-model="item.coverageRatio"
                    :config="{
                      type: 'input',
                      isNum: true,
                      label: '承保比例：',
                    }"
                  >
                    <slot slot="inputAppend">%</slot>
                  </TextInput>
                </div>
              </el-form-item>

              <div
                v-if="isEdit"
                style="
                  display: flex;
                  align-items: center;
                  width: 200px;
                  position: absolute;
                  right: 0;
                  top: 68%;
                "
              >
                <div
                  class="el-radio"
                  style="
                    display: flex;
                    align-items: center;
                    margin-right: 0;
                    padding: 0 10px 0 10px;
                    border-left: 1px solid #dcdfe6;
                    font-size: 14px;
                  "
                  @click="handleChangeProtagonist(index)"
                >
                  <div
                    :class="{
                      'el-radio__input': true,
                      'is-checked': item.isPrimary == 1,
                    }"
                  >
                    <div class="el-radio__inner"></div>
                  </div>
                  <div style="margin-left: 2px">主承保公司</div>
                </div>
                <el-button
                  v-if="
                    ruleForm.insInsurerList &&
                    isEdit &&
                    ruleForm.insInsurerList.length > 1
                  "
                  @click="
                    dataItemDelet({
                      i: index,
                      lk: 'ruleForm.insInsurerList',
                      idsKey: 'insInsurerIds',
                      id: item.id,
                    })
                  "
                  type="text"
                  icon="el-icon-circle-close"
                  style="
                    padding: 0 0 0 10px;
                    color: #f56c6c;
                    border-left: 1px solid #dcdfe6;
                    font-size: 14px;
                  "
                  >删除</el-button
                >
              </div>
            </div>
          </div>
          <!-- 用于显示校验错误信息 -->
          <el-form-item prop="insInsurerList"> </el-form-item>
        </AssociationScrollView>
        <AssociationScrollView title="被保险人" name="2">
          <el-form-item prop="insInsured.insuredName">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.insInsured.insuredName"
              :config="{
                type: 'input',
                label: '被保险人名称：',
                required: true,
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item class="certificate-label" prop="insInsured.idType">
            <template v-if="isEdit" slot="label">
              <div
                style="margin-top: 20px; margin-right: 40px"
                class="certificate-box"
              >
                <span> <span style="color: #f56c6c">*</span> 证件类型： </span>
                <div class="certificate-box-right">
                  <div @click="isExampleDialog = true" class="example">
                    示例
                  </div>
                  <el-upload
                    action="#"
                    :auto-upload="false"
                    :show-file-list="false"
                    :http-request="() => {}"
                    :on-change="
                      (file, fileList) => {
                        certificateRecognition(file, fileList);
                      }
                    "
                    ><div class="label-button">证件识别</div></el-upload
                  >
                </div>
              </div>
            </template>
            <TextInput
              :isText="!isEdit"
              @returnVal="getKeyToValue"
              v-model="ruleForm.insInsured.idTypeKey"
              :optionConfig="{
                value: 'dictKey',
                label: 'dictValue',
              }"
              :config="{
                disabled: true,
                type: 'select',
                option: dictList.identCardTypeConst,
                label: isEdit ? '' : '证件类型：',
                required: !isEdit,
                modelTextKey: 'idType',
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="insInsured.idNumber">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.insInsured.idNumber"
              :config="{
                type: 'input',
                label: '证件号码：',
                required: true,
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="insInsured.areaDist">
            <!-- TODO 所在区域 校验 -->
            <TextInput
              :isText="!isEdit"
              :form="ruleForm.insInsured"
              v-model="ruleForm.insInsured.areaDist"
              @returnVal="getKeyToValue($event, ruleForm.insInsured)"
              :config="{
                type: 'distpicker',
                regionKeys: ['areaProvince', 'areaCity'],
                modelTextKey: 'areaDist',
                label: '所在区域：',
                required: true,
              }"
            ></TextInput>
          </el-form-item>
          <el-form-item prop="insInsured.insuredAddress">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.insInsured.insuredAddress"
              :config="{
                type: 'input',
                label: '被保险人地址：',
                required: true,
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="insInsured.contactNumber">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.insInsured.contactNumber"
              :config="{
                type: 'input',
                isNum: true,
                label: '联系电话：',
                required: true,
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="insInsured.industryTypeKey">
            <TextInput
              :isText="!isEdit"
              @returnVal="getKeyToValue($event, ruleForm.insInsured)"
              v-model="ruleForm.insInsured.industryTypeKey"
              :optionConfig="{
                value: 'dictKey',
                label: 'dictValue',
              }"
              :config="{
                type: 'select',
                option: dictList.IndustryType,
                modelTextKey: 'industryName',
                label: '行业类型：',
                required: true,
              }"
            >
            </TextInput>
          </el-form-item>
        </AssociationScrollView>
        <AssociationScrollView title="标的信息" name="3">
          <el-form-item prop="insSubject.businessAddressName">
            <!-- <TextInput
              :isText="!isEdit"
              @returnVal="getKeyToValue"
              v-model="ruleForm.insSubject.businessAddressKey"
              :optionConfig="{
                value: 'dictKey',
                label: 'dictValue',
              }"
              :config="{
                type: 'select',
                option: dictList.OperationAddress,
                modelTextKey: 'businessAddressName',
                label: '生产经营地址：',
                required: true,
              }"
            ></TextInput> -->
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.insSubject.businessAddressName"
              :config="{
                type: 'input',
                modelTextKey: 'businessAddressName',
                label: '生产经营地址：',
                required: true,
              }"
            ></TextInput>
          </el-form-item>
          <el-form-item prop="insSubject.employeeCount">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.insSubject.employeeCount"
              :config="{
                type: 'inputNumber',
                label: '从业人员人数：',
                required: true,
              }"
            ></TextInput>
          </el-form-item>
          <el-form-item prop="insSubject.insuredEmployeeCount">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.insSubject.insuredEmployeeCount"
              :config="{
                type: 'inputNumber',
                required: true,
                label: '投保员工人数：',
              }"
            ></TextInput>
          </el-form-item>
          <el-form-item prop="insSubject.insuredEmployeeOccupation">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.insSubject.insuredEmployeeOccupation"
              :config="{
                type: 'input',
                label: '投保员工工种：',
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="insSubject.isSafetyCertificationRequired">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.insSubject.isSafetyCertificationRequired"
              :config="{
                type: 'radio',
                option: dictList.isOrNot,
                label: '是否要求安监证明：',
              }"
            ></TextInput>
          </el-form-item>
          <el-form-item prop="insSubject.isNamed"
            ><TextInput
              :isText="!isEdit"
              v-model="ruleForm.insSubject.isNamed"
              :config="{
                type: 'radio',
                option: dictList.isOrNot,
                label: '是否记名：',
              }"
            ></TextInput>
          </el-form-item>
          <!-- 从业人员名单： -->
          <div v-if="ruleForm.insSubject.isNamed == 1">
            <SimpleTable
              :tableProps="tableConfigs.insEmployeeTableSet"
              :tableData="ruleForm.insEmployeeList"
            >
              <template slot="right-l">
                <el-upload
                  v-if="isEdit"
                  style="margin-bottom: 16px"
                  action="#"
                  :auto-upload="false"
                  :show-file-list="false"
                  :http-request="() => {}"
                  :on-change="
                    (file, fileList) => {
                      importPeopleFile(file, fileList);
                    }
                  "
                  ><el-button type="primary">导入</el-button></el-upload
                >
              </template>
              <template slot="idType" slot-scope="{ data }">
                <TextInput
                  :isText="!isEdit"
                  v-model="data.row.idTypeKey"
                  :optionConfig="{
                    value: 'dictKey',
                    label: 'dictValue',
                  }"
                  :config="{
                    type: 'select',
                    option: dictList.identCardType,
                    width: '100%',
                  }"
                >
                </TextInput>
              </template>
              <template slot="name" slot-scope="{ data }">
                <TextInput
                  :isText="!isEdit"
                  v-model="data.row.name"
                  :config="{
                    type: 'input',
                    width: '100%',
                  }"
                >
                </TextInput>
              </template>
              <template slot="idNumber" slot-scope="{ data }">
                <TextInput
                  :isText="!isEdit"
                  v-model="data.row.idNumber"
                  :config="{
                    type: 'input',
                    width: '100%',
                  }"
                >
                </TextInput>
              </template>
              <template v-if="isEdit" slot="action" slot-scope="{ data }">
                <el-button
                  @click="
                    dataItemDelet({
                      lk: 'ruleForm.insEmployeeList',
                      i: data.index,
                      vt: 'insEmployeeList',
                      idsKey: 'insEmployeeListIds',
                      id: data.row.id,
                    })
                  "
                  type="text"
                  >删除</el-button
                >
              </template>
            </SimpleTable>
            <el-button
              v-if="isEdit"
              class="sd-table-bottom-btn"
              size="mini"
              @click="
                dataItemAdd({
                  tp: 'insEmployeeTemplate',
                  lk: 'ruleForm.insEmployeeList',
                  vt: 'insEmployeeList',
                })
              "
              >添加人员</el-button
            >
            <!-- 用于显示校验错误信息 -->
            <el-form-item prop="insEmployeeList"> </el-form-item>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="行业标的信息" name="4">
          <el-form-item prop="industryTypeKey">
            <TextInput
              :isText="!isEdit"
              @returnVal="getKeyToValue"
              v-model="ruleForm.industryTypeKey"
              :optionConfig="{
                value: 'dictKey',
                label: 'dictValue',
              }"
              :config="{
                type: 'select',
                option: dictList.IndustryType,
                modelTextKey: 'industryTypeName',
                label: '行业：',
              }"
            ></TextInput>
          </el-form-item>
          <el-form-item prop="engineeringProjectName">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.engineeringProjectName"
              :config="{
                type: 'input',
                label: '工程项目名称：',
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="projectTypeKey">
            <TextInput
              :isText="!isEdit"
              @returnVal="getKeyToValue"
              v-model="ruleForm.projectTypeKey"
              :optionConfig="{
                value: 'dictKey',
                label: 'dictValue',
              }"
              :config="{
                type: 'select',
                option: dictList.EngineeringProjectType,
                modelTextKey: 'projectTypeName',
                label: '工程项目类型：',
              }"
            ></TextInput>
          </el-form-item>
          <el-form-item prop="projectAddress">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.projectAddress"
              :config="{
                type: 'input',
                label: '工程项目地址：',
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="industryTypeValue">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.commencementDate"
              @returnVal="getKeyToValue"
              :form="ruleForm"
              :config="{
                type: 'datePicker',
                dateFormat: 'YYYY-MM-DD',
                label: '开工日期：',
              }"
            ></TextInput>
          </el-form-item>
          <el-form-item prop="constructionPeriodValue">
            <!-- <TextInput
              :isText="!isEdit"
              @returnVal="getKeyToValue"
              v-model="ruleForm.constructionPeriodKey"
              :optionConfig="{
                value: 'dictKey',
                label: 'dictValue',
              }"
              :config="{
                type: 'select',
                option: dictList.Duration,
                modelTextKey: 'constructionPeriodValue',
                label: '工期：',
              }"
            ></TextInput> -->
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.constructionPeriodValue"
              :config="{
                type: 'input',
                modelTextKey: 'constructionPeriodValue',
                label: '工期：',
              }"
            ></TextInput>
          </el-form-item>
          <el-form-item prop="projectCost">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.projectCost"
              :config="{
                type: 'input',
                isNum: true,
                label: '工程造价：',
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="buildingArea">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.buildingArea"
              :config="{
                type: 'input',
                isNum: true,
                label: '建筑面积：',
              }"
            >
            </TextInput>
          </el-form-item>
          <el-form-item prop="contractSubTypeValue">
            <TextInput
              :isText="!isEdit"
              @returnVal="getKeyToValue"
              v-model="ruleForm.contractSubTypeKey"
              :optionConfig="{
                value: 'dictKey',
                label: 'dictValue',
              }"
              :config="{
                type: 'select',
                option: dictList.ContractSubType,
                modelTextKey: 'contractSubTypeName',
                label: '施工合同分包类型：',
              }"
            ></TextInput>
          </el-form-item>
        </AssociationScrollView>
        <AssociationScrollView title="保障内容" name="5">
          <div>
            <el-form-item prop="insCoverage.insuranceNameKey">
              <TextInput
                v-if="isEdit"
                @returnVal="getKeyToValue($event, ruleForm.insCoverage)"
                v-model="ruleForm.insCoverage.insuranceNameKey"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                :config="{
                  type: 'select',
                  option: dictList.insuranceNameList,
                  modelTextKey: 'insuranceName',
                  label: '险别名称：',
                }"
              ></TextInput>
              <TextInput
                v-else
                isText
                v-model="ruleForm.insCoverage.insuranceName"
                :config="{
                  type: 'input',
                  label: '险别名称：',
                }"
              ></TextInput>
            </el-form-item>
          </div>
          <div>
            <div>保险方案：</div>
            <SimpleTable
              :tableProps="tableConfigs.insCoverageTableSet"
              :tableData="ruleForm.insCoverageTypeList"
            >
              <template v-if="isEdit" slot="action" slot-scope="{ data }">
                <el-button
                  @click="
                    dataItemDelet({
                      lk: 'ruleForm.insCoverageTypeList',
                      i: data.index,
                      idsKey: 'insCoverageTypeIds',
                      id: data.row.id,
                    })
                  "
                  type="text"
                  >删除</el-button
                >
              </template>
              <template slot="typeKey" slot-scope="{ data }">
                <TextInput
                  :isText="!isEdit"
                  @returnVal="getKeyToValue"
                  v-model="data.row.typeKey"
                  :config="{
                    type: 'select',
                    option: dictList.InsuranceTypes,
                    modelTextKey: 'typeName',
                    width: '100%',
                  }"
                ></TextInput>
              </template>
              <template slot="limitType" slot-scope="{ data }">
                <TextInput
                  :isText="!isEdit"
                  v-model="data.row.limitType"
                  :config="{
                    type: 'input',
                    width: '100%',
                  }"
                >
                </TextInput>
              </template>
              <template slot="limitValue" slot-scope="{ data }">
                <TextInput
                  :isText="!isEdit"
                  v-model="data.row.limitValue"
                  :config="{
                    type: 'input',
                    width: '100%',
                  }"
                >
                </TextInput>
              </template>
              <template slot="description" slot-scope="{ data }">
                <TextInput
                  :isText="!isEdit"
                  v-model="data.row.description"
                  :config="{
                    type: 'input',
                    width: '100%',
                  }"
                >
                </TextInput>
              </template>
              <template slot="baseRate" slot-scope="{ data }">
                <TextInput
                  :isText="!isEdit"
                  v-model="data.row.baseRate"
                  :config="{
                    type: 'input',
                    width: '100%',
                  }"
                >
                </TextInput>
              </template>
            </SimpleTable>
            <el-button
              v-if="isEdit"
              class="sd-table-bottom-btn"
              size="mini"
              @click="
                dataItemAdd({
                  tp: 'insCoverageTemplate',
                  lk: 'ruleForm.insCoverageTypeList',
                })
              "
              >添加</el-button
            >
          </div>
          <div>
            <div>
              <el-form-item prop="accidentCompensationLimit">
                <TextInput
                  :isText="!isEdit"
                  v-model="ruleForm.insCoverage.accidentCompensationLimit"
                  :config="{
                    type: 'input',
                    isNum: true,
                    label: '保单每次事故赔偿限额：',
                  }"
                >
                </TextInput>
              </el-form-item>
              <el-form-item prop="accumulativeCompensationLimit">
                <TextInput
                  :isText="!isEdit"
                  v-model="ruleForm.insCoverage.accumulativeCompensationLimit"
                  :config="{
                    type: 'input',
                    isNum: true,
                    label: '保单累计赔偿限额：',
                  }"
                >
                </TextInput>
              </el-form-item>
            </div>
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.insCoverage.deductible"
              :config="{
                label: '免赔额：',
                type: 'textarea',
                width: '600px',
              }"
            >
            </TextInput>

            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.insCoverage.specialAgreement"
              :config="{
                label: '特别约定：',
                type: 'textarea',
                width: '600px',
              }"
            >
            </TextInput>
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.insCoverage.limitDescription"
              :config="{
                label: '限额描述：',
                type: 'textarea',
                width: '600px',
              }"
            >
            </TextInput>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="主险保费" name="6">
          <el-form-item prop="mainInsurancePremium">
            <TextInput
              :isText="!isEdit"
              @returnVal="getKeyToValue"
              v-model="ruleForm.mainInsurancePremium"
              :config="{
                type: 'input',
                isNum: true,
                modelTextKey: 'mainInsurancePremium',
                label: '主保费：',
                required: true,
              }"
            >
            </TextInput> </el-form-item
          ><el-form-item prop="additionalInsurancePremium">
            <TextInput
              :isText="!isEdit"
              @returnVal="getKeyToValue"
              v-model="ruleForm.additionalInsurancePremium"
              :config="{
                type: 'input',
                isNum: true,
                modelTextKey: 'additionalInsurancePremium',
                label: '附加险保费：',
                required: true,
              }"
            >
            </TextInput> </el-form-item
          ><el-form-item prop="totalPremium">
            <TextInput
              :isText="!isEdit"
              v-model="ruleForm.totalPremium"
              :config="{
                placeholder: '自动填写',
                disabled: true,
                type: 'input',
                label: '总保费：',
              }"
            >
            </TextInput>
          </el-form-item>
        </AssociationScrollView>
        <AssociationScrollView title="电子保单" name="7">
          <FormListUpload
            :isEdit="isEdit"
            required
            title="电子保单"
            :limit="2"
            :limitType="['pdf', 'jpg', 'png', 'jpeg']"
            tips="支持格式：.jpg .jpeg .png .pdf  ，单个文件不能超过2MB，最多上传2个文件"
            attachmentType="sdPolicy"
            v-model="ruleForm.insPolicyAttachmentList"
            @change="$refs.ruleForm.validateField('insPolicyAttachmentList')"
          ></FormListUpload>
          <!-- 用于显示错误信息 -->
          <el-form-item prop="insPolicyAttachmentList"> </el-form-item>
        </AssociationScrollView>
        <AssociationScrollView title="风险减量" name="8">
          <!-- <div v-if="isEdit">
            <el-form-item
              label="风险减量服务名称："
              prop="riskReductionServiceName"
            >
              <el-select
                v-model="ruleForm.riskReductionServiceName"
                placeholder="请选择"
                @change="handleServiceItemChange"
                clearable
                :disabled="!isEdit"
              >
                <el-option
                  v-for="item in dictList.ServiceProjectName"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div> -->
          <div>
            <!-- <div>服务项目：</div> -->
            <SimpleTable
              :tableProps="tableConfigs.insServicePlanTableSet"
              :tableData="ruleForm.insServicePlanList"
            >
              <template v-if="isEdit" slot="action" slot-scope="{ data }">
                <el-button
                  @click="
                    dataItemDelet({
                      lk: 'ruleForm.insServicePlanList',
                      i: data.index,
                      idsKey: 'insServicePlanIds',
                      id: data.row.id,
                    })
                  "
                  type="text"
                  >删除</el-button
                >
              </template>
              <template slot="serviceName" slot-scope="{ data }">
                <TextInput
                  @returnVal="getKeyToValue($event, data.row)"
                  :isText="!isEdit"
                  v-model="data.row.serviceDictId"
                  :config="{
                    type: 'select',
                    width: '100%',
                    modelTextKey: 'serviceName',
                    option: dictList.ServiceProjectName,
                  }"
                  :optionConfig="{ value: 'dictKey', label: 'dictValue' }"
                >
                </TextInput>
              </template>
              <template slot="serviceStandard" slot-scope="{ data }">
                <TextInput
                  :isText="!isEdit"
                  v-model="data.row.serviceStandard"
                  :config="{
                    type: 'input',
                    width: '100%',
                  }"
                >
                </TextInput>
              </template>
              <template slot="serviceAmount" slot-scope="{ data }">
                <TextInput
                  :isText="!isEdit"
                  v-model="data.row.serviceAmount"
                  :config="{
                    type: 'input',
                    width: '100%',
                    isNum: true,
                  }"
                >
                </TextInput>
              </template>
            </SimpleTable>
            <el-button
              v-if="isEdit"
              class="sd-table-bottom-btn"
              size="mini"
              @click="
                dataItemAdd({
                  tp: 'insServiceTemplate',
                  lk: 'ruleForm.insServicePlanList',
                })
              "
              >添加项目</el-button
            >
          </div>
        </AssociationScrollView>
        <div v-if="!isEdit">
          <AssociationScrollView title="服务记录" name="9">
            <div style="font-size: 14px; color: #333333">
              服务总金额(元)：
              <span
                style="color: #3470ff; font-size: 18px; font-weight: bold"
                >{{ dataStatistics.serviceDataTotal || 0 }}</span
              >
            </div>
            <SimpleTable2
              ref="servicetable"
              :tableProps="tableConfigs.insServiceRecordTableSet"
              :queryFun="queryFunService"
            ></SimpleTable2>
          </AssociationScrollView>
          <AssociationScrollView title="理赔记录" name="10">
            <div style="font-size: 14px; color: #333333">
              赔付总金额(元)：
              <span
                style="color: #3470ff; font-size: 18px; font-weight: bold"
                >{{ dataStatistics.claimDataTotal || 0 }}</span
              >
            </div>
            <SimpleTable2
              ref="claimtable"
              :tableProps="tableConfigs.insClaimRecordTableSet"
              :queryFun="queryFunClaim"
            ></SimpleTable2
          ></AssociationScrollView>
        </div>
      </AssociationScroll>
      <!-- 底部按钮 -->
      <div class="sd-bottom-btn">
        <el-button @click="back">返回</el-button>
        <el-button v-if="!ruleForm.id" type="primary" @click="submitForm(2)"
          >保存草稿箱</el-button
        >
        <el-button v-if="isEdit" type="primary" @click="submitForm(1)"
          >保存</el-button
        >
      </div>
    </el-form>
    <!-- 地图选择地址 -->
    <!-- <positionDialog v-model="isPositionDialog" @submit="handleAddressVal" /> -->
    <!-- 关联上一年保单 -->
    <!-- <AssociationPolicy
      v-model="isAssociationDialog"
      @submit="handleChangeDetails"
      :excludePolicyId="excludePolicyId"
    /> -->
    <!-- 标的信息导入 -->
    <!-- <subjectImport
      v-model="isSubjectDialog"
      @changeImport="handleChangeSubjectList"
    /> -->
    <!-- 示例  sd保留-->
    <ExampleDialog v-model="isExampleDialog" typeName="business" />
    <!-- 示例 -->
    <!-- <IdentityCardUpDate
      v-model="isIdentityCard"
      @identify="handleIdentifySuccess"
    /> -->
    <!-- <clauseDialog v-model="innerVisible" :info="clauseInfo" /> -->
    <!-- <repetitionHistory
      v-model="repetitionHistoryVisible"
      :formData="repetitionHistoryForm"
    /> -->
    <projectDialog
      :dialogVisible.sync="bindProjectOpen"
      @submit="bindProject"
      :multiple="false"
    ></projectDialog>
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import ExampleDialog from "@/views/policyManagement/components/ExampleDialog.vue";
import FormListUpload from "@/components/FormListUpload/index.vue";
import { dictionaryBatch, pageMyCustomer } from "@/api/policy";
import dayjs from "dayjs";
import projectDialog from "@/views/SystemManagement/components/customer/projectDialog.vue";
//////////////////////////////
import _ from "lodash";
import { verifyArryEmpty, verifyNumberBiggerThen } from "../js/verify.js";
import {
  getInsuranceCompanyInfos,
  importProfessional,
  submitLiabilityInsurancePolicy,
  getServiceListByPolicyId,
  getCaseListByPolicyId,
  getServiceProjectOptions,
} from "@/api/safeDuty.js";
import { ocrBusinessLicense } from "@/api/claimData.js";
import { OSSDirectPass } from "@/api/oss.js";
import TextInput from "../components/TextInput.vue";
import SimpleTable from "../components/SimpleTable.vue";
import SimpleTable2 from "@/components/SimpleTable";
import { areaTextToCode } from "../js/area.js";
import SdEmployeeSelection from "../components/SdEmployeeSelection.vue";
//////////////////////////////
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  // watch: {
  //   "ruleForm.salesmanId": {
  //     handler(n) {
  //       console.log(n);
  //     },
  //   },
  // },
  data() {
    return {
      // // // // 安全责任险
      dictList: { AZXInsuranceType: [], ServiceProjectOptions: [] },
      //添加模板
      templates: {
        //承保公司
        insInsurerTemplate: {
          branchKey: "",
          branchName: "",
          branchValue: "",
          companyId: "",
          companyKey: "",
          companyName: "",
          companyValue: "",
          coverageRatio: "",
          createTime: "",
          createUser: "",
          isDeleted: "",
          isPrimary: 2,
          policyId: "",
          updateTime: "",
          updateUser: "",
        },
        //从业人员
        insEmployeeTemplate: {
          createTime: "",
          createUser: "",
          idNumber: "",
          idType: "",
          idTypeKey: "",
          idTypeValue: "",
          name: "",
          policyId: "",
          subjectId: "",
        },
        //服务项目
        insServiceTemplate: {
          insCompanyId: "",
          policyId: "",
          serviceName: "",
          serviceStandard: "",
          serviceAmount: "",
        },
        //保险方案
        insCoverageTemplate: {
          typeKey: "",
          typeName: "",
          limitType: "",
          limitValue: "",
          description: "",
          baseRate: "",
        },
      },
      // 表格配置
      tableConfigs: {
        insEmployeeTableSet: {
          isSelection: false,
          isColumnSet: false,
          isHiddenSet: false,
          isSearch: false,
          isPagination: false,
          isRefresh: false,
          isFuzzyQuery: false,
          isFillData: true,
          border: true,
          height: 260,
          columnProps: [
            {
              prop: "index",
              label: "序号",
              width: "55",
              type: "index",
              isShow: true,
            },
            {
              prop: "name",
              label: "姓名",
              isShow: true,
              slot: true,
            },

            {
              prop: "idType",
              label: "证件类型",
              isShow: true,
              slot: true,
            },
            {
              prop: "idNumber",
              label: "证件号码",
              isShow: true,
              slot: true,
            },
            {
              prop: "action",
              label: "操作",
              width: "80",
              isShow: true,
              slot: true,
            },
          ],
        },
        insServicePlanTableSet: {
          isSelection: false,
          isColumnSet: false,
          isHiddenSet: false,
          isSearch: false,
          isPagination: false,
          isRefresh: false,
          isFuzzyQuery: false,
          isFillData: true,
          border: true,
          height: 280,
          columnProps: [
            {
              prop: "serviceName",
              label: "服务项目名称",
              isShow: true,
              slot: true,
              slot: true,
            },
            {
              prop: "serviceStandard",
              label: "服务项目标准",
              isShow: true,
              slot: true,
            },
            {
              prop: "serviceAmount",
              label: "服务项目金额",
              isShow: true,
              slot: true,
            },
            {
              prop: "action",
              label: "操作",
              isShow: this.isEdit,
              width: 80,
              slot: true,
            },
          ],
        },
        //保险方案
        insCoverageTableSet: {
          isSelection: false,
          isColumnSet: false,
          isHiddenSet: false,
          isSearch: false,
          isPagination: false,
          isRefresh: false,
          isFuzzyQuery: false,
          isFillData: true,
          border: true,
          height: 260,
          columnProps: [
            {
              prop: "typeKey",
              label: "险种",
              isShow: true,
              slot: true,
              slot: true,
            },
            {
              prop: "limitType",
              label: "限额类型",
              isShow: true,
              slot: true,
            },
            {
              prop: "limitValue",
              label: "限额值",
              isShow: true,
              slot: true,
            },
            {
              prop: "description",
              label: "相关说明",
              isShow: true,
              slot: true,
            },
            {
              prop: "baseRate",
              label: "基础费率",
              isShow: true,
              slot: true,
            },
            {
              prop: "action",
              label: "操作",
              isShow: true,
              width: 80,
              slot: true,
            },
          ],
        },
        //服务记录表格
        insServiceRecordTableSet: {
          isSelection: false,
          isColumnSet: false,
          isHiddenSet: false,
          isSearch: false,
          isRefresh: false,
          isFuzzyQuery: false,
          border: true,
          height: 260,
          currentPageKey: "current",
          currentSizeKey: "size",
          totalKey: "total",
          columnProps: [
            {
              prop: "index",
              label: "ID",
              type: "index",
              isShow: true,
            },
            {
              prop: "serviceNo",
              label: "服务编号",
              isShow: true,
            },
            {
              prop: "serviceTypesOfValue",
              label: "服务类型",
              isShow: true,
            },
            {
              prop: "serviceTypeValue",
              label: "服务种类",
              isShow: true,
            },
            {
              prop: "designateExpert",
              label: "服务专家",
              isShow: true,
            },
            {
              prop: "expertServiceAmount",
              label: "专家金额",
              isShow: true,
            },
            {
              prop: "serviceAmount",
              label: "服务金额",
              isShow: true,
            },
            {
              prop: "serviceDate",
              label: "服务时间",
              isShow: true,
            },
            {
              prop: "reportStatus",
              label: "报告状态",
              isShow: true,
            },
          ],
        },
        //理赔记录表格
        insClaimRecordTableSet: {
          isSelection: false,
          isColumnSet: false,
          isHiddenSet: false,
          isSearch: false,
          isRefresh: false,
          isFuzzyQuery: false,
          border: true,
          height: 260,
          currentPageKey: "current",
          currentSizeKey: "size",
          totalKey: "total",
          columnProps: [
            {
              prop: "index",
              label: "ID",
              type: "index",
              isShow: true,
            },
            {
              prop: "caseNo",
              label: "案件编号",
              isShow: true,
            },
            {
              prop: "realCaseNo",
              label: "报案号",
              isShow: true,
            },
            {
              prop: "dangerType",
              label: "出险类型",
              isShow: true,
            },
            {
              prop: "accidentType",
              label: "事故类型",
              isShow: true,
            },
            {
              prop: "appraisalAgency",
              label: "公估机构",
              isShow: true,
            },
            {
              prop: "caseOfficer",
              label: "案件跟进人",
              isShow: true,
            },
            {
              prop: "dangerDate",
              label: "出险时间",
              isShow: true,
            },
            {
              prop: "compensationTotalAmount",
              label: "赔付总金额",
              isShow: true,
            },
            {
              prop: "createTime",
              label: "报案时间",
              isShow: true,
            },
          ],
        },
      },
      // 数据
      ruleForm: {
        commencementDate: "",
        accidentCompensationLimit: "",
        accumulativeCompensationLimit: "",
        additionalInsurancePremium: "",
        buildingArea: "",
        constructionPeriod: "",
        contractSubTypeKey: "",
        contractSubTypeName: "",
        contractSubTypeValue: "",
        coverageName: "",
        customerId: "",
        customerName: "",
        deductible: "",
        endDate: "",
        engineeringProjectName: "",
        id: "",
        industryTypeKey: "",
        industryTypeName: "",
        industryTypeValue: "",
        inputPersonId: "",
        inputPersonName: "",
        insCoverage: {
          accidentCompensationLimit: "",
          accumulativeCompensationLimit: "",
          deductible: "",
          insCompanyId: "",
          insuranceName: "",
          insuranceNameKey: "",
          limitDescription: "",
          policyId: "",
          specialAgreement: "",
        },
        insCoverageTypeIds: [],
        insCoverageTypeList: [],
        insEmployeeListIds: [],
        insEmployeeList: [],
        insInsured: {
          areaCity: "",
          areaProvince: "",
          contactNumber: "",
          idNumber: "",
          idType: "统一社会信用代码",
          idTypeKey: 1,
          idTypeValue: 1,
          industryName: "",
          industryTypeKey: "",
          industryTypeValue: "",
          insuredAddress: "",
          insuredName: "",
          //辅助
          areaDist: [],
        },
        insInsurerList: [],
        insPolicyAttachmentList: [],
        insServicePlanIds: [],
        insServicePlanList: [],
        insSubject: {
          businessAddressKey: "",
          businessAddressName: "",
          businessAddressValue: "",
          employeeCount: "",
          insuredEmployeeCount: "",
          insuredEmployeeOccupation: "",
          isNamed: "",
          isSafetyCertificationRequired: "",
          policyId: "",
        },
        insuranceCompanyName: "",
        insuranceEndDate: "",
        insuranceStartDate: "",
        insuranceTypeKey: "",
        insuranceTypeName: "",
        insuranceTypeValue: "",
        insuredId: "",
        insuredIdNumber: "",
        insuredName: "",
        limitDescription: "",
        mainInsurancePremium: "",
        periodStartEndTime: "",
        planName: "",
        policyNumber: "",
        projectAddress: "",
        projectCost: "",
        projectId: "",
        projectName: "",
        projectRemarks: "",
        projectTypeKey: "",
        projectTypeName: "",
        projectTypeValue: "",
        riskReductionServiceName: "",
        salesmanDepartmentId: "",
        salesmanDepartmentName: "",
        salesmanId: "",
        salesmanName: "",
        specialAgreement: "",
        startDate: "",
        totalPremium: "",
        //辅助用
        insuranceDate: [],
      },
      // sd保留
      bindProjectOpen: false,
      rules: {
        insuranceTypeKey: [
          { required: true, message: "请选择保险类型", trigger: "change" },
        ],
        insuranceDate: [
          { required: true, message: "请选择保险期限", trigger: "change" },
        ],
        periodStartEndTime: [
          { required: true, message: "请选择起止时间", trigger: "change" },
        ],
        salesmanId: [
          {
            required: true,
            message: "请选择业务员",
            trigger: "change",
          },
        ],
        projectId: [
          {
            required: true,
            message: "请选择项目名称",
            trigger: "change",
          },
        ],
        policyNumber: [
          {
            required: true,
            message: "请输入保单号",
            trigger: "change",
          },
        ],
        mainInsurancePremium: [
          {
            required: true,
            message: "请输入主险保费",
            trigger: "change",
          },
        ],
        additionalInsurancePremium: [
          {
            required: true,
            message: "请输入附加险保费",
            trigger: "change",
          },
        ],
        "insInsured.insuredName": [
          {
            required: true,
            message: "请输入被保险人名称",
            trigger: "change",
          },
        ],
        insEmployeeList: [
          {
            validator: verifyArryEmpty,
            message: "请添加从业人员",
            trigger: "blur",
          },
        ],
        insPolicyAttachmentList: [
          {
            validator: verifyArryEmpty,
            message: "请添加电子保单",
            trigger: "blur",
          },
        ],
        insInsurerList: [
          {
            validator: verifyArryEmpty,
            message: "请添加承保公司",
            trigger: "blur",
          },
        ],
        "insInsured.areaDist": [
          {
            validator: verifyArryEmpty,
            message: "请选择所在区域",
            trigger: "blur",
            required: true,
          },
        ],
        "insInsured.idNumber": [
          {
            required: true,
            message: "请输入证件号码",
            trigger: "change",
          },
        ],
        "insInsured.insuredAddress": [
          {
            required: true,
            message: "请输入被保险人地址",
            trigger: "change",
          },
        ],
        "insInsured.contactNumber": [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "change",
          },
        ],
        "insInsured.industryTypeKey": [
          {
            required: true,
            message: "请选择行业类型",
            trigger: "change",
          },
        ],
        "insSubject.businessAddressName": [
          {
            required: true,
            message: "请选择生产经营地址",
            trigger: "change",
          },
        ],
        "insSubject.employeeCount": [
          {
            required: true,
            message: "请输入从业人员人数",
            trigger: "change",
          },
          {
            validator: verifyNumberBiggerThen,
            message: "从业人员人数要大于0",
            trigger: "change",
            min: 0,
          },
        ],
        "insSubject.insuredEmployeeCount": [
          {
            required: true,
            message: "请输入投保员工人数",
            trigger: "change",
          },
          {
            validator: verifyNumberBiggerThen,
            message: "投保员工人数要大于0",
            trigger: "change",
            min: 0,
          },
        ],
      },

      startType: 2,

      // sd保留
      isExampleDialog: false,
      // 数据统计
      dataStatistics: {
        //服务数据
        serviceDataTotal: 0,
        //理赔数据
        claimDataTotal: 0,
      },
    };
  },
  components: {
    AssociationScroll,
    AssociationScrollView,
    //VDistpicker,
    //positionDialog,
    EmployeeSelect,
    // AssociationPolicy,
    // subjectImport,
    ExampleDialog,
    // IdentityCardUpDate,
    //clauseDialog,
    FormListUpload,
    // repetitionHistory,
    projectDialog,
    //automaticCalculation,
    ///////////////
    TextInput,
    SimpleTable,
    ///////////////
    SdEmployeeSelection,
    SimpleTable2,
  },
  created() {
    let init = this.isEdit && !this.detailData;
    this.sdInitData(init);
  },
  watch: {
    detailData(n) {
      if (n) {
        this.ruleForm = { ...this.ruleForm, ...n };
        if (!this.isEdit) {
          this.$refs.servicetable.getData();
          this.$refs.claimtable.getData();
        }
        this.findMainCom();
      }
    },
  },
  computed: {},
  methods: {
    //处理详情数据
    dealDetail() {
      //起保时间  ['insuranceStartDate', 'insuranceEndDate']->insuranceDate
      this.$set(this.ruleForm, "insuranceDate", [
        this.ruleForm.insuranceStartDate,
        this.ruleForm.insuranceEndDate,
      ]);
    },
    // sd保留
    handleChangeProtagonist(ind) {
      // if (!this.isEdit) return;
      for (
        let index = 0;
        index < this.ruleForm.insInsurerList.length;
        index++
      ) {
        // 1 主 2 不是
        if (index == ind) {
          this.ruleForm.insInsurerList[index].isPrimary = 1;
          this.getInsuranceNameListByMainInsurer(
            this.ruleForm.insInsurerList[index]
          );
        } else {
          this.ruleForm.insInsurerList[index].isPrimary = 2;
        }
      }
    },
    //寻找主承保公司
    findMainCom() {
      for (let i = 0; i < this.ruleForm.insInsurerList.length; i++) {
        let el = this.ruleForm.insInsurerList[i];
        if (el.isPrimary == 1) {
          this.handleChangeProtagonist(i);
          break;
        }
      }
    },
    // TODO 要优化起止时间
    setDatePicker() {
      if (this.ruleForm.periodStartEndTime == 1) {
        let addY = dayjs(this.ruleForm.insuranceStartDate)
          .add(1, "year")
          .format("YYYY-MM-DD");
        this.ruleForm.insuranceEndDate = addY;
        this.ruleForm.insuranceDate.splice(1, 1, addY);
      } else if (this.ruleForm.periodStartEndTime == 2) {
        let addYD = dayjs(
          dayjs(this.ruleForm.insuranceStartDate)
            .add(1, "year")
            .format("YYYY-MM-DD")
        )
          .subtract(1, "day")
          .format("YYYY-MM-DD");
        this.ruleForm.insuranceEndDate = addYD;
        this.ruleForm.insuranceDate.splice(1, 1, addYD);
      }
    },

    // NOTE 保留 计算短期费率
    calculationShortTermRates(mon = 0) {
      //9-12月递减 5n
      let rate = 0;
      let month = mon;
      if (!mon) month = 0;
      let years = Math.floor(month / 12);
      month = month % 12;
      month = Math.ceil(month);
      if (month > 8) {
        let interval = month - 8;
        rate = years + (month * 0.1 - 0.05 * interval);
      } else {
        rate = years + month * 0.1;
      }
      return rate;
    },

    // sd保留
    bindProject(e, p) {
      this.ruleForm.projectId = e.projectId[0];
      this.ruleForm.projectName = p[0].projectName;
      this.bindProjectOpen = false;
      this.$refs.ruleForm.validateField("projectId");
    },
    sdInitData(init = false) {
      if (init) {
        this.ruleForm.inputPersonId = this.$store.state.userInfo.MJUserId;
        this.ruleForm.inputPersonName = this.$store.state.userInfo.name;
        //保险期限默认值
        this.ruleForm.insuranceDate = [
          dayjs().format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ];
        this.ruleForm.insuranceStartDate = this.ruleForm.insuranceDate[0];
        this.ruleForm.insuranceEndDate = this.ruleForm.insuranceDate[1];
      } else {
      }
      this.getDictionarySd();
    },
    //字典获取
    getDictionarySd(e) {
      let dictKeys = [
        "AZXInsuranceType",
        "insuranceCompany",
        "IndustryType",
        "OperationAddress",
        "EngineeringProjectType",
        "Duration",
        "ContractSubType",
        "identCardType",
      ];
      // 默认写死的字典
      // 证件类型
      this.dictList.identCardTypeConst = [
        {
          dictKey: 1,
          dictValue: "统一社会信用代码",
        },
      ];
      // 是否 1 是 2 否
      this.dictList.isOrNot = [
        {
          value: 1,
          label: "是",
        },
        {
          value: 2,
          label: "否",
        },
      ];
      this.dictList.InsuranceTypes = [
        {
          value: 1,
          label: "主险",
        },
        {
          value: 2,
          label: "附加险",
        },
      ];
      dictionaryBatch({
        codes: dictKeys.join(","),
      }).then((res) => {
        if (res && res.data) {
          dictKeys.forEach((key) => {
            this.dictList[key] = res.data[key] || [];
          });
        }
      });
      // 获取客户列表
      pageMyCustomer({ size: 999 }).then((res) => {
        let customerList = res.data.records.map((item) => {
          return {
            ...item,
            dictValue: item.userName + ":" + item.phoneNum,
          };
        });
        this.$set(this.dictList, "customerList", customerList);
      });
      // 服务字典
      getServiceProjectOptions().then((res) => {
        let ServiceProjectName =
          res?.data?.map((item) => {
            let resdict = {
              serviceDictId: item.id,
              dictKey: item.id,
              dictValue: item.serviceName,
              serviceAmount: item.serviceAmount,
              serviceName: item.serviceName,
              serviceStandard: item.serviceStandard,
            };
            return resdict;
          }) || [];
        this.$set(this.dictList, "ServiceProjectName", ServiceProjectName);
        console.log(this.isEdit, "this.isEdit");
        if (!this.ruleForm.id) {
          console.log("this.addAllServiceitem()");
          this.addAllServiceitem();
        }
      });
    },
    getKeyToValue(res, res2) {
      if (res.type == "companyName") {
        res2.branchKey = "";
        res2.branchName = "";
        res2.branchList = [];
        let branchList = res.data.remark.split(",").map((bc) => {
          return {
            dictKey: bc,
            dictValue: bc,
          };
        });
        this.$set(res2, "branchList", branchList);
      } else if (res.type == "branchName") {
        this.getInsuranceNameListByMainInsurer(res2);
      } else if (res.type == "areaDist") {
        Object.keys(res.data).forEach((key) => {
          if (res2) {
            res2[key] = res.data[key];
          } else {
            this.ruleForm[key] = res.data[key];
          }
        });
      } else if (res.type == "insuranceName") {
        //切换前删除其他带id的
        let deletList = _.cloneDeep(this.ruleForm.insCoverageTypeList);
        deletList.forEach((item, index) => {
          if (item.id) {
            this.ruleForm.insCoverageTypeIds.push(item.id);
          }
        });
        this.ruleForm.insCoverageTypeList = [];
        ////
        let objKeys = [
          "accidentCompensationLimit",
          "accumulativeCompensationLimit",
          "deductible",
          "specialAgreement",
          "limitDescription",
        ];
        this.ruleForm.insCoverageTypeList = [];
        res.data?.insCoverageTypeList?.forEach((inspj) => {
          this.dataItemAdd({
            lk: "ruleForm.insCoverageTypeList",
            data: {
              ...inspj,
              id: "",
            },
          });
        });
        objKeys.forEach((key) => {
          res2[key] = res.data.insCoverage[key];
        });
      } else if (
        res.type == "additionalInsurancePremium" ||
        res.type == "mainInsurancePremium"
      ) {
        this.ruleForm.totalPremium =
          Number(this.ruleForm.additionalInsurancePremium) +
          Number(this.ruleForm.mainInsurancePremium);
      } else if (res.type == "periodStartEndTimeName") {
        this.setDatePicker();
      } else if (res.type == "serviceName") {
        // res2.serviceName = res.data.serviceName;
        res2.serviceAmount = res.data.serviceAmount;
        res2.serviceStandard = res.data.serviceStandard;
      }
      // 处理日期组件 comType 组件类型
      if (res.comType == "daterange") {
        res.data.daterangeKeys.forEach((key, index) => {
          this.ruleForm[key] = res.data.dates[key];
        });
      } else if (res.comType == "select") {
        if (res2) {
          res2[res.type] = res.data.dictValue;
        } else {
          this.ruleForm[res.type] = res.data.dictValue;
        }
      }
    },
    dataItemAdd(addObj = { tp: "", lk: "", data: null }) {
      // addObj {tp 模板 lk 添加到的目标数组 }
      if (addObj.tp || addObj.data) {
        let sps = addObj.lk.split(".");
        let obj = this;
        let templates = _.cloneDeep(this.templates[addObj.tp]);
        sps.forEach((sp) => {
          obj = obj[sp];
        });
        if (addObj.data) {
          obj.push(_.cloneDeep(addObj.data));
        } else {
          obj.push(templates);
        }
      }
      if (addObj.vt) {
        this.$refs.ruleForm.validateField(addObj.vt);
      }
    },
    // 添加保单风险带出所有服务项目
    addAllServiceitem() {
      // this.dictList.ServiceProjectName.forEach((item) => {
      //   this.dataItemAdd({
      //     tp: "insServiceTemplate",
      //     lk: "ruleForm.insServicePlanList",
      //     data: item,
      //   });
      // });
    },
    // handleServiceItemChange(e) {
    //   let res = this.findDictItem(e, "ServiceProjectName");
    //   this.dataItemAdd({
    //     tp: "insServiceTemplate",
    //     lk: "ruleForm.insServicePlanList",
    //     data: {
    //       serviceName: res.dictValue,
    //       serviceStandard: res.serviceStandard,
    //     },
    //   });
    // },
    dataItemDelet(
      delObj = { tp: "", lk: "", i: "", id: "", idsKey: "", vt: "" }
    ) {
      console.log("执行", delObj.i);
      if (delObj.lk && delObj.i !== "") {
        let sps = delObj.lk.split(".");
        let obj = this;
        sps.forEach((sp) => {
          obj = obj[sp];
        });
        delObj.id && this.ruleForm[delObj.idsKey].push(delObj.id);
        obj.splice(delObj.i, 1);
      }
      if (delObj.vt) {
        this.$refs.ruleForm.validateField(delObj.vt);
      }
    },
    findDictItem(key = "", dict = "") {
      if (key && dict) {
        return (
          this.dictList[dict]?.find((item) => {
            return item.dictKey === key;
          }) || null
        );
      }
    },
    // 根据主承保公司获取险别名称
    getInsuranceNameListByMainInsurer(item) {
      // dictList.insuranceNameList
      let { branchName, companyName } = item;
      if (item.isPrimary == 1 && branchName && companyName) {
        getInsuranceCompanyInfos({
          branch: branchName,
          companyName: companyName,
        }).then((res) => {
          let insuranceNameList =
            res?.data?.coverageList?.map((item) => {
              return {
                ...item,
                dictKey: item.insCoverage.id,
                dictValue: item.insCoverage.insuranceName,
              };
            }) || [];
          this.$set(this.dictList, "insuranceNameList", insuranceNameList);
          // let ServiceProjectName =
          //   res?.data?.servicePlanList?.map((item) => {
          //     return {
          //       ...item,
          //       dictKey: item.serviceDictId,
          //       dictValue: item.serviceName,
          //     };
          //   }) || [];

          // this.$set(this.dictList, "ServiceProjectName", ServiceProjectName);
        });
      }
    },
    async certificateRecognition(file, fileList) {
      const loading = this.$loading({
        lock: true,
        text: "识别中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let fileUrl = await OSSDirectPass(file);
      if (fileUrl) {
        ocrBusinessLicense({ ossUrl: fileUrl }).then(
          (res) => {
            this.ruleForm.insInsured.insuredName = res.data.companyName;
            this.ruleForm.insInsured.idNumber = res.data.creditCode;
            this.ruleForm.insInsured.insuredAddress = res.data.businessAddress;
            this.ruleForm.insInsured.areaProvince = res.data.province;
            this.ruleForm.insInsured.areaCity =
              res.data.province[res.data.province.length - 1] == "市"
                ? res.data.city
                : res.data.district;
            if (res.data.city && res.data.province) {
              if (res.data.province[res.data.province.length - 1] == "市") {
                this.ruleForm.insInsured.areaDist = areaTextToCode([
                  res.data.province,
                  res.data.district,
                ]);
              } else {
                this.ruleForm.insInsured.areaDist = areaTextToCode([
                  res.data.province,
                  res.data.city,
                ]);
              }
            }
            loading.close();
          },
          () => {
            loading.close();
          }
        );
      }
    },
    carryOutData(event, ...data) {
      if (data[0] == "salesmanId") {
        this.ruleForm.salesmanName = event.title;
        this.ruleForm.salesmanDepartmentId = event.parentId;
        this.ruleForm.salesmanDepartmentName = event.parentName;
        this.$refs.ruleForm.validateField("salesmanId");
      }
    },
    //从业人员名单导入
    importPeopleFile(file, fileList) {
      let formData = new FormData();
      formData.append("file", file.raw);
      importProfessional(formData).then((res) => {
        if (res.data && res.data.length) {
          res.data.forEach((item) => {
            this.ruleForm.insEmployeeList.push(item);
          });
        }
      });
    },
    submitForm(type = 1) {
      // TODO 之后有空再优化吧
      if (
        this.ruleForm.insInsured.areaProvince &&
        this.ruleForm.insInsured.areaCity
      ) {
        this.ruleForm.insInsured.areaDist = [
          this.ruleForm.insInsured.areaProvince,
          this.ruleForm.insInsured.areaCity,
        ];
      } else {
        this.ruleForm.insInsured.areaDist = [];
      }
      if (type == 1) {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            submitLiabilityInsurancePolicy({
              ...this.ruleForm,
              policyType: type,
            }).then((res) => {
              this.$message({
                type: "success",
                message: "保单保存成功!",
              });
              this.back();
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        submitLiabilityInsurancePolicy({
          ...this.ruleForm,
          policyType: type,
        }).then((res) => {
          this.$message({
            type: "success",
            message: "草稿保存成功!",
          });
          this.back();
        });
      }
    },
    back() {
      this.$router.go(-1);
      // this.$router.replace({ path: this.$route.path });
    },
    async queryFunService(queryParams) {
      if (this.isEdit || !this.detailData) return;
      let res = await getServiceListByPolicyId(queryParams, this.detailData.id);
      this.dataStatistics.serviceDataTotal = res.data.serviceTotalAmount || 0;
      return {
        localData: res.data.serviceManagementIPage.records || [],
        total: res.data.serviceManagementIPage.total,
      };
    },
    async queryFunClaim(queryParams) {
      if (this.isEdit || !this.detailData) return;
      let res = await getCaseListByPolicyId(queryParams, this.detailData.id);
      this.dataStatistics.claimDataTotal = res.data.compensateTotalAmount || 0;
      return {
        localData: res.data.insAzxClaimsIPage.records || [],
        total: res.data.insAzxClaimsIPage.total,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
// ////////////////
::v-deep .el-table {
  border-radius: unset;
}
.sd-bottom-btn {
  width: 100%;
  height: 80px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;
  z-index: 99;
}
.sd-table-bottom-btn {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #00bc0d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
}
// /////////////////
.container {
  padding: 0;
}
.container-box {
  padding: 18px 12px 0;
  border-top: 1px dashed #dedede;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  .personnel_repetition {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333333;
    padding: 8px 12px;
    margin-bottom: 24px;
    background: #cce6ff;
    border-radius: 8px;
    &_details {
      color: #0080ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.el-collapse-box {
  height: 100%;
  .view-title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      height: 48px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      img {
        width: 6px;
        height: 24px;
        margin-right: 8px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-float {
      position: fixed;
      background-color: #ffffff;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 12px 24px 0;
      margin: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      z-index: 3;
    }
  }
  .el-collapse {
    border: none;
    .el-collapse-item {
      margin-bottom: 10px;
      padding: 24px;
      border-radius: 8px;
      border: none;
      background-color: #fff;
      overflow: hidden;
      ::v-deep .el-collapse-item__arrow {
        display: none;
      }
    }
  }
  .merge-box {
    display: flex;
    align-items: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .merge-date {
      ::v-deep .el-input__inner {
        padding: 0;
        text-align: center;
        border: none;
      }
      ::v-deep .el-input__prefix {
        display: none;
      }
    }
  }
  .merge-box {
    display: flex;
    align-items: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 3px 10px;
    > span {
      display: block;
      text-align: center;
      width: 20px;
    }
    .merge-date {
      flex: 1;
      text-align: center;
      ::v-deep .el-input__inner {
        padding: 0;
        text-align: center;
        border: none;
      }
      ::v-deep .el-input__prefix {
        display: none;
      }
    }
  }
  .el-select {
    width: 100%;
  }
  .el-autocomplete {
    width: 100%;
  }
  ::v-deep .el-descriptions-item__cell {
    padding-bottom: 20px;
  }
  ::v-deep .el-form-item {
    width: 32%;
    //margin-bottom: 18px;
    .el-form-item__content {
      width: 100%;
    }
  }
  @media (max-width: 1500px) {
    ::v-deep .el-form-item {
      width: 45%;
      .el-form-item__content {
        width: 85%;
      }
    }
  }
  @media (max-width: 1000px) {
    ::v-deep .el-form-item {
      width: 100%;
      .el-form-item__content {
        width: 90%;
      }
    }
  }
  .plan-table {
    display: column;
    ::v-deep .el-form-item {
      width: 100%;
      .el-form-item__content {
        width: 100%;
      }
      .el-icon--right {
        margin-left: 0;
      }
    }
  }
  ::v-deep .el-date-editor {
    width: 100%;
  }
  .input-customization {
    ::v-deep .el-input__inner {
      padding-right: 72px;
    }
  }
  .certificate-label {
    ::v-deep .el-form-item__label {
      width: 70%;
    }
    .certificate-box {
      display: flex;
      justify-content: space-between;
      &-right {
        display: flex;
        align-items: center;
        .example {
          font-size: 12px;
          color: #0080ff;
          margin-right: 8px;
          cursor: pointer;
        }
        .label-button {
          height: 24px;
          line-height: 24px;
          font-size: 14px;
          padding: 0 6px;
          color: #ffaa00;
          background: rgba(255, 170, 0, 0.16);
          border-radius: 4px;
          border: 1px solid #ffaa00;
          cursor: pointer;
        }
      }
    }
  }
  .insurance-company {
    display: flex;
    justify-content: flex-end;
    max-width: 1000px;
    font-weight: bold;
    color: #4278c9;
    font-size: 16px;
    margin-top: 16px;
  }
  .insurance-clause {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 1000px;
    color: #00bc0d;
    font-size: 14px;
    border-top: none;
    border: 1px solid #ebeef5;
    cursor: pointer;
  }
  .input-position {
    .input-suffix {
      position: relative;
      width: 44px;
      height: 100%;
      border-left: 1px solid #dcdfe6;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);
      }
    }
    ::v-deep .el-input__inner {
      padding-right: 48px;
    }
  }
}
.clause-title {
  margin: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.list-item {
  &:first-child {
    border: none;
    padding-top: 0;
  }
  padding-top: 10px;
  border-top: 1px dashed #999;

  position: relative;
  .del-button {
    position: absolute;
    right: 0;
  }
}
</style>
<style lang="scss">
.policy-label-style {
  font-size: 14px;
  color: #333333;
  margin-bottom: 12px;
}
.distpicker-address-wrapper select {
  width: 100%;
  border-radius: 4px;
  padding: 0;
  height: 32px;
  font-size: 13px;
  min-width: 80px;
}
.distpicker-all-box select {
  width: 30%;
}
.hide-picker label:nth-of-type(1) {
  display: none;
}
.popper-customization {
  background: rgba(255, 170, 0, 0.16) !important;
  color: #ffaa00 !important;
  border: 1px solid #ffaa00 !important;
  // .popper__arrow::after {
  //   border-top-color: #747574;
  // }
}
.popper-customization[x-placement^="top"] .popper__arrow::after {
  border-top-color: #ffaa00 !important;
}
.popper-customization[x-placement^="top"] .popper__arrow {
  border-top-color: #ffaa00 !important;
}
.el-collapse-box {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
}
.is-primary {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  padding: 2px 6px;
  background: #fca118;
  border-radius: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  height: 21px;
}
</style>
