<template>
  <sdPolicyForm
    :isEdit="$route.params.isEdit"
    :detailData="detailData"
  ></sdPolicyForm>
</template>
<script>
import sdPolicyForm from "./sdPolicyForm.vue";
import { getLiabilityInsurancePolicy } from "@/api/safeDuty.js";
export default {
  components: { sdPolicyForm },
  data() {
    return {
      detailData: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getDetail(this.$route.params.id);
    } else {
      this.detailData = null;
    }
  },
  methods: {
    async getDetail(id = "") {
      let res = await getLiabilityInsurancePolicy({
        policyId: this.$route.params.id,
      });
      this.detailData = res.data;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped></style>
