var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"el-collapse-box"},[_c('el-form',{ref:"ruleForm",staticClass:"el-collapse-box",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"inline":"","label-position":"top","label-width":"100px"}},[_c('AssociationScroll',[_c('AssociationScrollView',{attrs:{"title":"基本信息","name":"0"}},[_c('div',{staticClass:"container container-box"},[_c('el-form-item',{attrs:{"prop":"insuranceTypeKey"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              },"config":{
                type: 'select',
                option: _vm.dictList.AZXInsuranceType,
                modelTextKey: 'insuranceTypeName',
                required: true,
                label: '保险类型:',
              }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.ruleForm.insuranceTypeKey),callback:function ($$v) {_vm.$set(_vm.ruleForm, "insuranceTypeKey", $$v)},expression:"ruleForm.insuranceTypeKey"}})],1),_c('el-form-item',{ref:"insuranceDate",attrs:{"prop":"insuranceDate"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"form":_vm.ruleForm,"config":{
                type: 'daterange',
                daterangeKeys: ['insuranceStartDate', 'insuranceEndDate'],
                daterangeFormats: ['YYYY-MM-DD', 'YYYY-MM-DD'],
                modelTextKey: 'insuranceDate',
                required: true,
                label: '保险期限:',
              }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.ruleForm.insuranceDate),callback:function ($$v) {_vm.$set(_vm.ruleForm, "insuranceDate", $$v)},expression:"ruleForm.insuranceDate"}})],1),_c('el-form-item',{attrs:{"prop":"periodStartEndTime"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                required: true,
                label: '起止时间：',
                type: 'radio',
                option: [
                  {
                    label: '12时-12时',
                    value: 1,
                  },
                  {
                    label: '0时-24时',
                    value: 2,
                  },
                ],
                daterangeFormats: ['YYYY-MM-DD', 'YYYY-MM-DD'],
                modelTextKey: 'periodStartEndTimeName',
              }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.ruleForm.periodStartEndTime),callback:function ($$v) {_vm.$set(_vm.ruleForm, "periodStartEndTime", $$v)},expression:"ruleForm.periodStartEndTime"}})],1),_c('el-form-item',{attrs:{"prop":"salesmanId"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                type: 'slot',
                label: '业务员：',
                required: true,
              }},model:{value:(_vm.ruleForm.salesmanName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "salesmanName", $$v)},expression:"ruleForm.salesmanName"}},[_c('EmployeeSelect',{attrs:{"placeholder":"请选择"},on:{"changeChecked":function($event){return _vm.carryOutData($event, 'salesmanId')}},model:{value:(_vm.ruleForm.salesmanId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "salesmanId", $$v)},expression:"ruleForm.salesmanId"}})],1)],1),_c('el-form-item',{ref:"inputPersonName",attrs:{"prop":"inputPersonName"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                disabled: true,
                type: 'input',
                label: '录单员：',
              }},model:{value:(_vm.ruleForm.inputPersonName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "inputPersonName", $$v)},expression:"ruleForm.inputPersonName"}})],1),_c('el-form-item',{ref:"projectId",attrs:{"prop":"projectId"}},[_c('div',{staticClass:"el-input el-input--small el-input--suffix"},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  required: true,
                  label: '项目名称：',
                }},nativeOn:{"click":function($event){_vm.isEdit ? (_vm.bindProjectOpen = true) : ''}},model:{value:(_vm.ruleForm.projectName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "projectName", $$v)},expression:"ruleForm.projectName"}})],1)]),_c('el-form-item',{attrs:{"prop":"projectRemarks"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                type: 'input',
                label: '项目备注：',
              }},model:{value:(_vm.ruleForm.projectRemarks),callback:function ($$v) {_vm.$set(_vm.ruleForm, "projectRemarks", $$v)},expression:"ruleForm.projectRemarks"}})],1),_c('el-form-item',{ref:"customerId",attrs:{"prop":"customerId"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"optionConfig":{
                value: 'userId',
                label: 'dictValue',
              },"config":{
                type: 'select',
                option: _vm.dictList.customerList,
                modelTextKey: 'customerName',
                label: '绑定客户：',
              }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.ruleForm.customerId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "customerId", $$v)},expression:"ruleForm.customerId"}})],1),_c('el-form-item',{attrs:{"prop":"policyNumber"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                type: 'input',
                label: '保单号：',
                required: true,
              }},model:{value:(_vm.ruleForm.policyNumber),callback:function ($$v) {_vm.$set(_vm.ruleForm, "policyNumber", $$v)},expression:"ruleForm.policyNumber"}})],1),_c('el-form-item',{ref:"salesmanDepartmentName",attrs:{"prop":"salesmanDepartmentName"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                disabled: true,
                type: 'input',
                required: true,
                label: '业务员所属部门：',
              }},model:{value:(_vm.ruleForm.salesmanDepartmentName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "salesmanDepartmentName", $$v)},expression:"ruleForm.salesmanDepartmentName"}})],1)],1)]),_c('AssociationScrollView',{attrs:{"title":"承保公司","name":"1"}},[(_vm.isEdit)?_c('template',{slot:"head-right"},[_c('el-button',{attrs:{"type":"success","icon":"el-icon-circle-plus-outline","size":"small"},on:{"click":function($event){return _vm.dataItemAdd({
                tp: 'insInsurerTemplate',
                lk: 'ruleForm.insInsurerList',
              })}}},[_vm._v("添加")])],1):_vm._e(),_c('div',{staticClass:"container"},_vm._l((_vm.ruleForm.insInsurerList),function(item,index){return _c('div',{key:index,ref:'acceptCompanyList' + index,refInFor:true,staticClass:"container-box",staticStyle:{"position":"relative"}},[_c('el-form-item',{attrs:{"prop":'insInsurerList.' + index + '.companyKey',"rules":{
                required: true,
                message: '请选择保险公司',
                trigger: 'change',
              }}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"optionConfig":{
                    value: 'dictKey',
                    label: 'dictValue',
                  },"config":{
                    type: 'select',
                    option: _vm.dictList.insuranceCompany,
                    modelTextKey: 'companyName',
                    label: '保险公司：',
                    required: true,
                  }},on:{"returnVal":function($event){return _vm.getKeyToValue($event, item)}},model:{value:(item.companyKey),callback:function ($$v) {_vm.$set(item, "companyKey", $$v)},expression:"item.companyKey"}}),(!_vm.isEdit && item.isPrimary == 1)?_c('div',{staticClass:"is-primary"},[_vm._v(" 主承保公司 ")]):_vm._e()],1)]),_c('el-form-item',{attrs:{"prop":'insInsurerList.' + index + '.branchKey',"rules":{
                required: true,
                message: '请选择分支机构',
                trigger: 'change',
              }}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"optionConfig":{
                  value: 'dictKey',
                  label: 'dictValue',
                },"config":{
                  type: 'select',
                  option: item.branchList,
                  modelTextKey: 'branchName',
                  label: '分支机构：',
                  required: true,
                }},on:{"returnVal":function($event){return _vm.getKeyToValue($event, item)}},model:{value:(item.branchKey),callback:function ($$v) {_vm.$set(item, "branchKey", $$v)},expression:"item.branchKey"}})],1),_c('el-form-item',{attrs:{"prop":'insInsurerList.' + index + '.coverageRatio'}},[_c('div',{staticStyle:{"display":"flex"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                    type: 'input',
                    isNum: true,
                    label: '承保比例：',
                  }},model:{value:(item.coverageRatio),callback:function ($$v) {_vm.$set(item, "coverageRatio", $$v)},expression:"item.coverageRatio"}},[_vm._t("default",function(){return [_vm._v("%")]},{"slot":"inputAppend"})],2)],1)]),(_vm.isEdit)?_c('div',{staticStyle:{"display":"flex","align-items":"center","width":"200px","position":"absolute","right":"0","top":"68%"}},[_c('div',{staticClass:"el-radio",staticStyle:{"display":"flex","align-items":"center","margin-right":"0","padding":"0 10px 0 10px","border-left":"1px solid #dcdfe6","font-size":"14px"},on:{"click":function($event){return _vm.handleChangeProtagonist(index)}}},[_c('div',{class:{
                    'el-radio__input': true,
                    'is-checked': item.isPrimary == 1,
                  }},[_c('div',{staticClass:"el-radio__inner"})]),_c('div',{staticStyle:{"margin-left":"2px"}},[_vm._v("主承保公司")])]),(
                  _vm.ruleForm.insInsurerList &&
                  _vm.isEdit &&
                  _vm.ruleForm.insInsurerList.length > 1
                )?_c('el-button',{staticStyle:{"padding":"0 0 0 10px","color":"#f56c6c","border-left":"1px solid #dcdfe6","font-size":"14px"},attrs:{"type":"text","icon":"el-icon-circle-close"},on:{"click":function($event){return _vm.dataItemDelet({
                    i: index,
                    lk: 'ruleForm.insInsurerList',
                    idsKey: 'insInsurerIds',
                    id: item.id,
                  })}}},[_vm._v("删除")]):_vm._e()],1):_vm._e()],1)}),0),_c('el-form-item',{attrs:{"prop":"insInsurerList"}})],2),_c('AssociationScrollView',{attrs:{"title":"被保险人","name":"2"}},[_c('el-form-item',{attrs:{"prop":"insInsured.insuredName"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'input',
              label: '被保险人名称：',
              required: true,
            }},model:{value:(_vm.ruleForm.insInsured.insuredName),callback:function ($$v) {_vm.$set(_vm.ruleForm.insInsured, "insuredName", $$v)},expression:"ruleForm.insInsured.insuredName"}})],1),_c('el-form-item',{staticClass:"certificate-label",attrs:{"prop":"insInsured.idType"}},[(_vm.isEdit)?_c('template',{slot:"label"},[_c('div',{staticClass:"certificate-box",staticStyle:{"margin-top":"20px","margin-right":"40px"}},[_c('span',[_c('span',{staticStyle:{"color":"#f56c6c"}},[_vm._v("*")]),_vm._v(" 证件类型： ")]),_c('div',{staticClass:"certificate-box-right"},[_c('div',{staticClass:"example",on:{"click":function($event){_vm.isExampleDialog = true}}},[_vm._v(" 示例 ")]),_c('el-upload',{attrs:{"action":"#","auto-upload":false,"show-file-list":false,"http-request":() => {},"on-change":(file, fileList) => {
                      _vm.certificateRecognition(file, fileList);
                    }}},[_c('div',{staticClass:"label-button"},[_vm._v("证件识别")])])],1)])]):_vm._e(),_c('TextInput',{attrs:{"isText":!_vm.isEdit,"optionConfig":{
              value: 'dictKey',
              label: 'dictValue',
            },"config":{
              disabled: true,
              type: 'select',
              option: _vm.dictList.identCardTypeConst,
              label: _vm.isEdit ? '' : '证件类型：',
              required: !_vm.isEdit,
              modelTextKey: 'idType',
            }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.ruleForm.insInsured.idTypeKey),callback:function ($$v) {_vm.$set(_vm.ruleForm.insInsured, "idTypeKey", $$v)},expression:"ruleForm.insInsured.idTypeKey"}})],2),_c('el-form-item',{attrs:{"prop":"insInsured.idNumber"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'input',
              label: '证件号码：',
              required: true,
            }},model:{value:(_vm.ruleForm.insInsured.idNumber),callback:function ($$v) {_vm.$set(_vm.ruleForm.insInsured, "idNumber", $$v)},expression:"ruleForm.insInsured.idNumber"}})],1),_c('el-form-item',{attrs:{"prop":"insInsured.areaDist"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"form":_vm.ruleForm.insInsured,"config":{
              type: 'distpicker',
              regionKeys: ['areaProvince', 'areaCity'],
              modelTextKey: 'areaDist',
              label: '所在区域：',
              required: true,
            }},on:{"returnVal":function($event){return _vm.getKeyToValue($event, _vm.ruleForm.insInsured)}},model:{value:(_vm.ruleForm.insInsured.areaDist),callback:function ($$v) {_vm.$set(_vm.ruleForm.insInsured, "areaDist", $$v)},expression:"ruleForm.insInsured.areaDist"}})],1),_c('el-form-item',{attrs:{"prop":"insInsured.insuredAddress"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'input',
              label: '被保险人地址：',
              required: true,
            }},model:{value:(_vm.ruleForm.insInsured.insuredAddress),callback:function ($$v) {_vm.$set(_vm.ruleForm.insInsured, "insuredAddress", $$v)},expression:"ruleForm.insInsured.insuredAddress"}})],1),_c('el-form-item',{attrs:{"prop":"insInsured.contactNumber"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'input',
              isNum: true,
              label: '联系电话：',
              required: true,
            }},model:{value:(_vm.ruleForm.insInsured.contactNumber),callback:function ($$v) {_vm.$set(_vm.ruleForm.insInsured, "contactNumber", $$v)},expression:"ruleForm.insInsured.contactNumber"}})],1),_c('el-form-item',{attrs:{"prop":"insInsured.industryTypeKey"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"optionConfig":{
              value: 'dictKey',
              label: 'dictValue',
            },"config":{
              type: 'select',
              option: _vm.dictList.IndustryType,
              modelTextKey: 'industryName',
              label: '行业类型：',
              required: true,
            }},on:{"returnVal":function($event){return _vm.getKeyToValue($event, _vm.ruleForm.insInsured)}},model:{value:(_vm.ruleForm.insInsured.industryTypeKey),callback:function ($$v) {_vm.$set(_vm.ruleForm.insInsured, "industryTypeKey", $$v)},expression:"ruleForm.insInsured.industryTypeKey"}})],1)],1),_c('AssociationScrollView',{attrs:{"title":"标的信息","name":"3"}},[_c('el-form-item',{attrs:{"prop":"insSubject.businessAddressName"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'input',
              modelTextKey: 'businessAddressName',
              label: '生产经营地址：',
              required: true,
            }},model:{value:(_vm.ruleForm.insSubject.businessAddressName),callback:function ($$v) {_vm.$set(_vm.ruleForm.insSubject, "businessAddressName", $$v)},expression:"ruleForm.insSubject.businessAddressName"}})],1),_c('el-form-item',{attrs:{"prop":"insSubject.employeeCount"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'inputNumber',
              label: '从业人员人数：',
              required: true,
            }},model:{value:(_vm.ruleForm.insSubject.employeeCount),callback:function ($$v) {_vm.$set(_vm.ruleForm.insSubject, "employeeCount", $$v)},expression:"ruleForm.insSubject.employeeCount"}})],1),_c('el-form-item',{attrs:{"prop":"insSubject.insuredEmployeeCount"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'inputNumber',
              required: true,
              label: '投保员工人数：',
            }},model:{value:(_vm.ruleForm.insSubject.insuredEmployeeCount),callback:function ($$v) {_vm.$set(_vm.ruleForm.insSubject, "insuredEmployeeCount", $$v)},expression:"ruleForm.insSubject.insuredEmployeeCount"}})],1),_c('el-form-item',{attrs:{"prop":"insSubject.insuredEmployeeOccupation"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'input',
              label: '投保员工工种：',
            }},model:{value:(_vm.ruleForm.insSubject.insuredEmployeeOccupation),callback:function ($$v) {_vm.$set(_vm.ruleForm.insSubject, "insuredEmployeeOccupation", $$v)},expression:"ruleForm.insSubject.insuredEmployeeOccupation"}})],1),_c('el-form-item',{attrs:{"prop":"insSubject.isSafetyCertificationRequired"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'radio',
              option: _vm.dictList.isOrNot,
              label: '是否要求安监证明：',
            }},model:{value:(_vm.ruleForm.insSubject.isSafetyCertificationRequired),callback:function ($$v) {_vm.$set(_vm.ruleForm.insSubject, "isSafetyCertificationRequired", $$v)},expression:"ruleForm.insSubject.isSafetyCertificationRequired"}})],1),_c('el-form-item',{attrs:{"prop":"insSubject.isNamed"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'radio',
              option: _vm.dictList.isOrNot,
              label: '是否记名：',
            }},model:{value:(_vm.ruleForm.insSubject.isNamed),callback:function ($$v) {_vm.$set(_vm.ruleForm.insSubject, "isNamed", $$v)},expression:"ruleForm.insSubject.isNamed"}})],1),(_vm.ruleForm.insSubject.isNamed == 1)?_c('div',[_c('SimpleTable',{attrs:{"tableProps":_vm.tableConfigs.insEmployeeTableSet,"tableData":_vm.ruleForm.insEmployeeList},scopedSlots:_vm._u([{key:"idType",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"optionConfig":{
                  value: 'dictKey',
                  label: 'dictValue',
                },"config":{
                  type: 'select',
                  option: _vm.dictList.identCardType,
                  width: '100%',
                }},model:{value:(data.row.idTypeKey),callback:function ($$v) {_vm.$set(data.row, "idTypeKey", $$v)},expression:"data.row.idTypeKey"}})]}},{key:"name",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  width: '100%',
                }},model:{value:(data.row.name),callback:function ($$v) {_vm.$set(data.row, "name", $$v)},expression:"data.row.name"}})]}},{key:"idNumber",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  width: '100%',
                }},model:{value:(data.row.idNumber),callback:function ($$v) {_vm.$set(data.row, "idNumber", $$v)},expression:"data.row.idNumber"}})]}},{key:"action",fn:function({ data }){return (_vm.isEdit)?[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.dataItemDelet({
                    lk: 'ruleForm.insEmployeeList',
                    i: data.index,
                    vt: 'insEmployeeList',
                    idsKey: 'insEmployeeListIds',
                    id: data.row.id,
                  })}}},[_vm._v("删除")])]:undefined}}],null,true)},[_c('template',{slot:"right-l"},[(_vm.isEdit)?_c('el-upload',{staticStyle:{"margin-bottom":"16px"},attrs:{"action":"#","auto-upload":false,"show-file-list":false,"http-request":() => {},"on-change":(file, fileList) => {
                    _vm.importPeopleFile(file, fileList);
                  }}},[_c('el-button',{attrs:{"type":"primary"}},[_vm._v("导入")])],1):_vm._e()],1)],2),(_vm.isEdit)?_c('el-button',{staticClass:"sd-table-bottom-btn",attrs:{"size":"mini"},on:{"click":function($event){return _vm.dataItemAdd({
                tp: 'insEmployeeTemplate',
                lk: 'ruleForm.insEmployeeList',
                vt: 'insEmployeeList',
              })}}},[_vm._v("添加人员")]):_vm._e(),_c('el-form-item',{attrs:{"prop":"insEmployeeList"}})],1):_vm._e()],1),_c('AssociationScrollView',{attrs:{"title":"行业标的信息","name":"4"}},[_c('el-form-item',{attrs:{"prop":"industryTypeKey"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"optionConfig":{
              value: 'dictKey',
              label: 'dictValue',
            },"config":{
              type: 'select',
              option: _vm.dictList.IndustryType,
              modelTextKey: 'industryTypeName',
              label: '行业：',
            }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.ruleForm.industryTypeKey),callback:function ($$v) {_vm.$set(_vm.ruleForm, "industryTypeKey", $$v)},expression:"ruleForm.industryTypeKey"}})],1),_c('el-form-item',{attrs:{"prop":"engineeringProjectName"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'input',
              label: '工程项目名称：',
            }},model:{value:(_vm.ruleForm.engineeringProjectName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "engineeringProjectName", $$v)},expression:"ruleForm.engineeringProjectName"}})],1),_c('el-form-item',{attrs:{"prop":"projectTypeKey"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"optionConfig":{
              value: 'dictKey',
              label: 'dictValue',
            },"config":{
              type: 'select',
              option: _vm.dictList.EngineeringProjectType,
              modelTextKey: 'projectTypeName',
              label: '工程项目类型：',
            }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.ruleForm.projectTypeKey),callback:function ($$v) {_vm.$set(_vm.ruleForm, "projectTypeKey", $$v)},expression:"ruleForm.projectTypeKey"}})],1),_c('el-form-item',{attrs:{"prop":"projectAddress"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'input',
              label: '工程项目地址：',
            }},model:{value:(_vm.ruleForm.projectAddress),callback:function ($$v) {_vm.$set(_vm.ruleForm, "projectAddress", $$v)},expression:"ruleForm.projectAddress"}})],1),_c('el-form-item',{attrs:{"prop":"industryTypeValue"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"form":_vm.ruleForm,"config":{
              type: 'datePicker',
              dateFormat: 'YYYY-MM-DD',
              label: '开工日期：',
            }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.ruleForm.commencementDate),callback:function ($$v) {_vm.$set(_vm.ruleForm, "commencementDate", $$v)},expression:"ruleForm.commencementDate"}})],1),_c('el-form-item',{attrs:{"prop":"constructionPeriodValue"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'input',
              modelTextKey: 'constructionPeriodValue',
              label: '工期：',
            }},model:{value:(_vm.ruleForm.constructionPeriodValue),callback:function ($$v) {_vm.$set(_vm.ruleForm, "constructionPeriodValue", $$v)},expression:"ruleForm.constructionPeriodValue"}})],1),_c('el-form-item',{attrs:{"prop":"projectCost"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'input',
              isNum: true,
              label: '工程造价：',
            }},model:{value:(_vm.ruleForm.projectCost),callback:function ($$v) {_vm.$set(_vm.ruleForm, "projectCost", $$v)},expression:"ruleForm.projectCost"}})],1),_c('el-form-item',{attrs:{"prop":"buildingArea"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'input',
              isNum: true,
              label: '建筑面积：',
            }},model:{value:(_vm.ruleForm.buildingArea),callback:function ($$v) {_vm.$set(_vm.ruleForm, "buildingArea", $$v)},expression:"ruleForm.buildingArea"}})],1),_c('el-form-item',{attrs:{"prop":"contractSubTypeValue"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"optionConfig":{
              value: 'dictKey',
              label: 'dictValue',
            },"config":{
              type: 'select',
              option: _vm.dictList.ContractSubType,
              modelTextKey: 'contractSubTypeName',
              label: '施工合同分包类型：',
            }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.ruleForm.contractSubTypeKey),callback:function ($$v) {_vm.$set(_vm.ruleForm, "contractSubTypeKey", $$v)},expression:"ruleForm.contractSubTypeKey"}})],1)],1),_c('AssociationScrollView',{attrs:{"title":"保障内容","name":"5"}},[_c('div',[_c('el-form-item',{attrs:{"prop":"insCoverage.insuranceNameKey"}},[(_vm.isEdit)?_c('TextInput',{attrs:{"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              },"config":{
                type: 'select',
                option: _vm.dictList.insuranceNameList,
                modelTextKey: 'insuranceName',
                label: '险别名称：',
              }},on:{"returnVal":function($event){return _vm.getKeyToValue($event, _vm.ruleForm.insCoverage)}},model:{value:(_vm.ruleForm.insCoverage.insuranceNameKey),callback:function ($$v) {_vm.$set(_vm.ruleForm.insCoverage, "insuranceNameKey", $$v)},expression:"ruleForm.insCoverage.insuranceNameKey"}}):_c('TextInput',{attrs:{"isText":"","config":{
                type: 'input',
                label: '险别名称：',
              }},model:{value:(_vm.ruleForm.insCoverage.insuranceName),callback:function ($$v) {_vm.$set(_vm.ruleForm.insCoverage, "insuranceName", $$v)},expression:"ruleForm.insCoverage.insuranceName"}})],1)],1),_c('div',[_c('div',[_vm._v("保险方案：")]),_c('SimpleTable',{attrs:{"tableProps":_vm.tableConfigs.insCoverageTableSet,"tableData":_vm.ruleForm.insCoverageTypeList},scopedSlots:_vm._u([{key:"action",fn:function({ data }){return (_vm.isEdit)?[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.dataItemDelet({
                    lk: 'ruleForm.insCoverageTypeList',
                    i: data.index,
                    idsKey: 'insCoverageTypeIds',
                    id: data.row.id,
                  })}}},[_vm._v("删除")])]:undefined}},{key:"typeKey",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'select',
                  option: _vm.dictList.InsuranceTypes,
                  modelTextKey: 'typeName',
                  width: '100%',
                }},on:{"returnVal":_vm.getKeyToValue},model:{value:(data.row.typeKey),callback:function ($$v) {_vm.$set(data.row, "typeKey", $$v)},expression:"data.row.typeKey"}})]}},{key:"limitType",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  width: '100%',
                }},model:{value:(data.row.limitType),callback:function ($$v) {_vm.$set(data.row, "limitType", $$v)},expression:"data.row.limitType"}})]}},{key:"limitValue",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  width: '100%',
                }},model:{value:(data.row.limitValue),callback:function ($$v) {_vm.$set(data.row, "limitValue", $$v)},expression:"data.row.limitValue"}})]}},{key:"description",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  width: '100%',
                }},model:{value:(data.row.description),callback:function ($$v) {_vm.$set(data.row, "description", $$v)},expression:"data.row.description"}})]}},{key:"baseRate",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  width: '100%',
                }},model:{value:(data.row.baseRate),callback:function ($$v) {_vm.$set(data.row, "baseRate", $$v)},expression:"data.row.baseRate"}})]}}],null,true)}),(_vm.isEdit)?_c('el-button',{staticClass:"sd-table-bottom-btn",attrs:{"size":"mini"},on:{"click":function($event){return _vm.dataItemAdd({
                tp: 'insCoverageTemplate',
                lk: 'ruleForm.insCoverageTypeList',
              })}}},[_vm._v("添加")]):_vm._e()],1),_c('div',[_c('div',[_c('el-form-item',{attrs:{"prop":"accidentCompensationLimit"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  isNum: true,
                  label: '保单每次事故赔偿限额：',
                }},model:{value:(_vm.ruleForm.insCoverage.accidentCompensationLimit),callback:function ($$v) {_vm.$set(_vm.ruleForm.insCoverage, "accidentCompensationLimit", $$v)},expression:"ruleForm.insCoverage.accidentCompensationLimit"}})],1),_c('el-form-item',{attrs:{"prop":"accumulativeCompensationLimit"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  isNum: true,
                  label: '保单累计赔偿限额：',
                }},model:{value:(_vm.ruleForm.insCoverage.accumulativeCompensationLimit),callback:function ($$v) {_vm.$set(_vm.ruleForm.insCoverage, "accumulativeCompensationLimit", $$v)},expression:"ruleForm.insCoverage.accumulativeCompensationLimit"}})],1)],1),_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              label: '免赔额：',
              type: 'textarea',
              width: '600px',
            }},model:{value:(_vm.ruleForm.insCoverage.deductible),callback:function ($$v) {_vm.$set(_vm.ruleForm.insCoverage, "deductible", $$v)},expression:"ruleForm.insCoverage.deductible"}}),_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              label: '特别约定：',
              type: 'textarea',
              width: '600px',
            }},model:{value:(_vm.ruleForm.insCoverage.specialAgreement),callback:function ($$v) {_vm.$set(_vm.ruleForm.insCoverage, "specialAgreement", $$v)},expression:"ruleForm.insCoverage.specialAgreement"}}),_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              label: '限额描述：',
              type: 'textarea',
              width: '600px',
            }},model:{value:(_vm.ruleForm.insCoverage.limitDescription),callback:function ($$v) {_vm.$set(_vm.ruleForm.insCoverage, "limitDescription", $$v)},expression:"ruleForm.insCoverage.limitDescription"}})],1)]),_c('AssociationScrollView',{attrs:{"title":"主险保费","name":"6"}},[_c('el-form-item',{attrs:{"prop":"mainInsurancePremium"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'input',
              isNum: true,
              modelTextKey: 'mainInsurancePremium',
              label: '主保费：',
              required: true,
            }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.ruleForm.mainInsurancePremium),callback:function ($$v) {_vm.$set(_vm.ruleForm, "mainInsurancePremium", $$v)},expression:"ruleForm.mainInsurancePremium"}})],1),_c('el-form-item',{attrs:{"prop":"additionalInsurancePremium"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              type: 'input',
              isNum: true,
              modelTextKey: 'additionalInsurancePremium',
              label: '附加险保费：',
              required: true,
            }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.ruleForm.additionalInsurancePremium),callback:function ($$v) {_vm.$set(_vm.ruleForm, "additionalInsurancePremium", $$v)},expression:"ruleForm.additionalInsurancePremium"}})],1),_c('el-form-item',{attrs:{"prop":"totalPremium"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
              placeholder: '自动填写',
              disabled: true,
              type: 'input',
              label: '总保费：',
            }},model:{value:(_vm.ruleForm.totalPremium),callback:function ($$v) {_vm.$set(_vm.ruleForm, "totalPremium", $$v)},expression:"ruleForm.totalPremium"}})],1)],1),_c('AssociationScrollView',{attrs:{"title":"电子保单","name":"7"}},[_c('FormListUpload',{attrs:{"isEdit":_vm.isEdit,"required":"","title":"电子保单","limit":2,"limitType":['pdf', 'jpg', 'png', 'jpeg'],"tips":"支持格式：.jpg .jpeg .png .pdf  ，单个文件不能超过2MB，最多上传2个文件","attachmentType":"sdPolicy"},on:{"change":function($event){return _vm.$refs.ruleForm.validateField('insPolicyAttachmentList')}},model:{value:(_vm.ruleForm.insPolicyAttachmentList),callback:function ($$v) {_vm.$set(_vm.ruleForm, "insPolicyAttachmentList", $$v)},expression:"ruleForm.insPolicyAttachmentList"}}),_c('el-form-item',{attrs:{"prop":"insPolicyAttachmentList"}})],1),_c('AssociationScrollView',{attrs:{"title":"风险减量","name":"8"}},[_c('div',[_c('SimpleTable',{attrs:{"tableProps":_vm.tableConfigs.insServicePlanTableSet,"tableData":_vm.ruleForm.insServicePlanList},scopedSlots:_vm._u([{key:"action",fn:function({ data }){return (_vm.isEdit)?[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.dataItemDelet({
                    lk: 'ruleForm.insServicePlanList',
                    i: data.index,
                    idsKey: 'insServicePlanIds',
                    id: data.row.id,
                  })}}},[_vm._v("删除")])]:undefined}},{key:"serviceName",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'select',
                  width: '100%',
                  modelTextKey: 'serviceName',
                  option: _vm.dictList.ServiceProjectName,
                },"optionConfig":{ value: 'dictKey', label: 'dictValue' }},on:{"returnVal":function($event){return _vm.getKeyToValue($event, data.row)}},model:{value:(data.row.serviceDictId),callback:function ($$v) {_vm.$set(data.row, "serviceDictId", $$v)},expression:"data.row.serviceDictId"}})]}},{key:"serviceStandard",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  width: '100%',
                }},model:{value:(data.row.serviceStandard),callback:function ($$v) {_vm.$set(data.row, "serviceStandard", $$v)},expression:"data.row.serviceStandard"}})]}},{key:"serviceAmount",fn:function({ data }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  width: '100%',
                  isNum: true,
                }},model:{value:(data.row.serviceAmount),callback:function ($$v) {_vm.$set(data.row, "serviceAmount", $$v)},expression:"data.row.serviceAmount"}})]}}],null,true)}),(_vm.isEdit)?_c('el-button',{staticClass:"sd-table-bottom-btn",attrs:{"size":"mini"},on:{"click":function($event){return _vm.dataItemAdd({
                tp: 'insServiceTemplate',
                lk: 'ruleForm.insServicePlanList',
              })}}},[_vm._v("添加项目")]):_vm._e()],1)]),(!_vm.isEdit)?_c('div',[_c('AssociationScrollView',{attrs:{"title":"服务记录","name":"9"}},[_c('div',{staticStyle:{"font-size":"14px","color":"#333333"}},[_vm._v(" 服务总金额(元)： "),_c('span',{staticStyle:{"color":"#3470ff","font-size":"18px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.dataStatistics.serviceDataTotal || 0))])]),_c('SimpleTable2',{ref:"servicetable",attrs:{"tableProps":_vm.tableConfigs.insServiceRecordTableSet,"queryFun":_vm.queryFunService}})],1),_c('AssociationScrollView',{attrs:{"title":"理赔记录","name":"10"}},[_c('div',{staticStyle:{"font-size":"14px","color":"#333333"}},[_vm._v(" 赔付总金额(元)： "),_c('span',{staticStyle:{"color":"#3470ff","font-size":"18px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.dataStatistics.claimDataTotal || 0))])]),_c('SimpleTable2',{ref:"claimtable",attrs:{"tableProps":_vm.tableConfigs.insClaimRecordTableSet,"queryFun":_vm.queryFunClaim}})],1)],1):_vm._e()],1),_c('div',{staticClass:"sd-bottom-btn"},[_c('el-button',{on:{"click":_vm.back}},[_vm._v("返回")]),(!_vm.ruleForm.id)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm(2)}}},[_vm._v("保存草稿箱")]):_vm._e(),(_vm.isEdit)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm(1)}}},[_vm._v("保存")]):_vm._e()],1)],1),_c('ExampleDialog',{attrs:{"typeName":"business"},model:{value:(_vm.isExampleDialog),callback:function ($$v) {_vm.isExampleDialog=$$v},expression:"isExampleDialog"}}),_c('projectDialog',{attrs:{"dialogVisible":_vm.bindProjectOpen,"multiple":false},on:{"update:dialogVisible":function($event){_vm.bindProjectOpen=$event},"update:dialog-visible":function($event){_vm.bindProjectOpen=$event},"submit":_vm.bindProject}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }